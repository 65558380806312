@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
$breakpoint-small: 1000px;
$breakpoint-big: 1200px;
$text-color: #fff6cc;

html,
body {
  width: 100%;
  height: 100%;
}
body {
  background-color: #6b6bb3;
  color: #dda0dd;
  background-image: url("./assets/moth\ decal.png"), none;
  background-repeat: repeat;
  background-size: 15vh;
  backdrop-filter: blur(1px);
  padding: 0;
}
body:before {
  /*
  background-attachment: scroll;
  content: "";
  display: block;
  height: var(--background-height);
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: scale(1);
  width: 100vw;
  z-index: 0;
  background-image: linear-gradient(45deg, #f2bfc5 27%, #f7d3b2 93%);
  background-size: 57px, auto;
  background-position: center, 0% 0%;
  background-repeat: repeat, repeat;
  */
}

.App {
  height: 100%;
  color: $text-color;
  font-family: "PT Sans", sans-serif;
  /* background-image: linear-gradient(45deg, #800020 27%, #410413 93%); */
  display: flex;
  .AppRoutes {
    width: 100%;
  }
  @media (max-width: $breakpoint-small) {
    display: block;
  }
}

.inlineLink {
  display: inline;
  text-decoration: underline;
  cursor: pointer;
}

.NavBar {
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
  width: 120px;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background-color: black;
  text-align: center;

  img {
    max-width: 100px;
  }
  /*
  display: flex;
  justify-content: space-between;
  color: $text-color;
  background: #6b6bb3;
  width: 100%;
  margin: 0px;
  padding-right: 0px;
  */
  @media (max-width: $breakpoint-small) {
    padding: 0px;
    width: 100vw;
    min-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    z-index: 10000;
    height: 100px;
  }
  a {
    color: $text-color;
    //   padding: 0 20px 0px 0px;
    &:hover {
      color: #ffb3f3;
    }
    text-decoration: none;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
    @media (max-width: $breakpoint-small) {
      padding: 0px;
      max-width: 30%;
    }
    &.active {
      color: white;
    }
    &:not(.active) {
      opacity: 0.8;
    }
    p{
      margin: 15px 0px; 
    }
  }

  .homeNav {
    position: relative;
    text-align: center;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
    }
    .HomeButtonImage {
      margin: 0 auto;
      display: block;
      border-radius: 2px;
      max-width: 90%;

      &:hover {
        filter: invert(85%) sepia(90%) saturate(2421%) hue-rotate(278deg)
          brightness(105%) contrast(109%);
      }
    }

    //   padding-left: 100px;
    @media (max-width: $breakpoint-small) {
    }
  }
  .galleryNav {
  }
  .NavTwitterSocials {
    fill: white;
    max-width: 40px;
    &:hover {
      cursor: pointer;
      fill: #ffb3f3;
    }
    margin-top: auto;
    margin-bottom: 15px;
    @media (max-width: $breakpoint-small) {
      margin: 5px;
    }
  }
}

.homePageLinkImage {
  max-width: 50%;
  margin: 100px auto;
  display: block;
}
.HomePage {
  max-width: 100vw;
  width: 90vw;

  @media (max-width: $breakpoint-small) {
    max-width: 100%;
    width: 100%;
  }

  .ProfilePictures {
    .ProfilePicturesTopContainer {
      padding: 10px 20px 0px 20px;
    }
    @media (max-width: $breakpoint-small) {
      display: block;
    }
    .ProfilePicturesInfoContainer {
      justify-content: flex-start;
      max-width: 100%;
      padding-right: 20px;
      position: relative;
      border-radius: 0.4em;
      display: flex;
      @media (max-width: $breakpoint-small) {
        display: block;
        max-width: 100%;
        padding: 0px;
        margin: 0 auto;
        width: 100%;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 30px;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-right-color: #6b6bb3;
        border-left: 0;
        border-top: 0;
        margin-top: 0px;
        margin-left: -15px;
        @media (max-width: $breakpoint-small) {
          display: none;
        }
      }

      align-items: center;
      .ProfilePicturesBanner {
        border-radius: 10px;
        max-height: 200px;
        margin: 0 auto;
        border: 2px solid black;

        &:hover {
          cursor: pointer;
          outline: 2px white solid;
        }
        @media (max-width: $breakpoint-big) {
          flex: 1;
          margin: 20px auto;
          display: block;
        }
      }
      .ProfilePicturesInfoBody {
        background: #6b6bb3;
        padding: 20px;
        padding-bottom: 0px;
        border-radius: 15px;
        max-width: 600px;
        margin-right: 10px;

        .ProfilePicturesInfoHeader {
          color: $text-color;
          font-size: 2em;
          letter-spacing: 0.1em;
          font-weight: bold;
          font-family: "PT Sans", sans-serif;
        }
        .ProfilePicturesInfoSubHeader {
          color: $text-color;
          font-size: 1.5em;
          font-weight: bold;
          font-family: "PT Sans", sans-serif;
          padding-bottom: 15px;
          p {
            margin: 10px 0px;
          }
        }
      }

      .ProfilePicturesLeftSideCommissionInfo {
        color: $text-color;
        font-size: 24px;
        font-weight: bold;
        font-family: "PT Sans", sans-serif;
        padding-top: 60px;
        max-width: 400px;
      }

      .ProfilePicturesLeftSideCommissionStatusHeader {
        color: $text-color;
        font-size: 40px;
        font-weight: bold;
        font-family: "PT Sans", sans-serif;
        padding-top: 60px;
        text-align: center;
      }

      .ProfilePicturesLeftSideCommissionStatusIndicator {
        color: $text-color;
        font-size: 48px;
        font-weight: bold;
        font-family: "PT Sans", sans-serif;

        text-align: center;
      }

      .ProfilePicturesLeftSideCTA {
        padding: 13px 20px;
        border-radius: 15px;

        text-align: center;
        max-width: 300px;
        font-size: 52px;
        font-weight: bold;
        font-family: "PT Sans", sans-serif;
        margin: 50px auto 0px;
        line-height: 1.2em;

        border: none;
        outline: none;
        color: rgb(0, 0, 0);
        background: rgb(255, 255, 255);
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;

        margin: auto auto;

        &:before {
          content: "";
          background: linear-gradient(
            45deg,
            #ff0000,
            #ff7300,
            #fffb00,
            #48ff00,
            #00ffd5,
            #002bff,
            #7a00ff,
            #ff00c8,
            #ff0000
          );
          position: absolute;
          top: -2px;
          left: -2px;
          background-size: 400%;
          z-index: -1;
          filter: blur(5px);
          -webkit-filter: blur(5px);
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          animation: glowing-button-85 20s linear infinite;
          transition: opacity 0.3s ease-in-out;
          border-radius: 10px;
        }

        &:after {
          z-index: -1;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgb(255, 255, 255);
          left: 0;
          top: 0;
          border-radius: 10px;
        }

        &:hover {
          color: #e5e7ef;
          &::after {
            background-color: #1f1f3f;
          }
        }
      }

      .ProfilePicturesInfoFooter {
        display: flex;
        justify-content: center;
        svg {
          fill: white;
          width: 42px;
          cursor: pointer;
          background: #1f1f3f;
          padding: 10px;
          border-radius: 30px;
          margin: 5px;
          &:hover {
            fill: #ffb3f3;
          }
          border-radius: 10px;
        }

        @keyframes glowing-button-85 {
          0% {
            background-position: 0 0;
          }
          50% {
            background-position: 400% 0;
          }
          100% {
            background-position: 0 0;
          }
        }
      }
    }
    .ProfilePicturesBottomContainer {
      width: 80vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 80px;
      margin-bottom: 20px;
      @media (max-width: $breakpoint-small) {
        margin: 0px;
        width: 100%;
      }
      .ProfilePicturesExplanationContainer {
        width: 100%;
        position: relative;
        font-size: 1em;
        margin-top: 15px;

        @media (max-width: $breakpoint-small) {
          width: 100%;
          display: block;
        }

        .ProfilePicturesInnerContainer {
          background-color: white;
          padding: 20px;
          border-radius: 10px 10px 0px 0px;
          @media (max-width: $breakpoint-small) {
            width: 90%;
            margin: 0 auto;
          }
          .artBox {
            padding: 20px 0px;
            width: 100%;
            align-items: center;
            @media (max-width: $breakpoint-small) {
              width: 100%;
            }
          }

          .discord {
            font-family: "PT Sans", sans-serif;
            display: flex;
            flex-direction: row;
            background: rgb(54, 57, 63);
            color: white;
            border-radius: 20px;
            margin-bottom: 20px;
            @media (max-width: $breakpoint-small) {
              display: block;
              margin: 0 auto;
              text-align: center;
            }
            .portfolioProfilePictureImage {
              width: 200px;
              object-fit: contain;
              align-self: flex-start;
              border-radius: 33%;
              margin: 20px 10px 10px 10px;
              height: 200px;
              object-fit: cover;

              @media (max-width: $breakpoint-small) {
                display: block;
                margin: 0 auto;
              }
            }

            .portfolioProfilePictureTextboxHeader {
              span {
                vertical-align: middle;
              }
              .portfolioProfilePictureTitle {
                font-weight: bold;
                font-size: 20px;
              }
              .portfolioProfilePictureDate {
                color: rgb(163, 166, 172);
                padding-left: 5px;
              }
            }
            .portfolioProfilePictureDescription {
              color: rgb(223, 223, 223);
              padding: 0px 20px 0px 0px;
              font-size: 1.5em;
              @media (max-width: $breakpoint-small) {
                padding: 10px;
                font-size: 1em;
              }
            }
          }
        }
        .ProfilePicturesInnerContainerText {
          color: black;
          text-align: center;
          margin: 0px;
          margin-top: -10px;
          padding: 0px;
          padding-bottom: 10px;
          @media (max-width: $breakpoint-small) {
            margin-top: 10px;
          }
        }
      }
      .ProfilePicSelectors {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        background-color: white;
        padding: 30px;
        border-radius: 0px 0px 10px 10px;
        @media (max-width: $breakpoint-small) {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          height: 300px;
          overflow-x: auto;
          width: auto;
          justify-content: left;
        }
        .profilePicSelector {
          width: 100%;
          flex: 0 0 calc(10%);
          /* margin: 5px; */
          margin: 2px;
          cursor: pointer;
          /*   height: 20vh;  */
          justify-content: center;
          @media (max-width: $breakpoint-small) {
            flex: 0 0 calc(50% - 10px);
          }
          .profilePicSelectorImage {
            /* width: 50px; 
height: 50px; */

            max-width: 100%;
            max-height: 100%;
            border-radius: 2px;
            &:not(.activeSelector) {
              -webkit-filter: grayscale(80%);
            }
            &:hover {
              -webkit-filter: grayscale(0);
            }
          }
        }
      }
    }
  }

  .TwitchLink {
    background: #6b6bb3;
    padding: 25px;
    border-radius: 15px;
    text-align: center;
    margin: 100px 50px;

    .TwitchHeader {
      color: #fff6cc;
      font-size: 72px;
      letter-spacing: 0.1em;
      font-weight: bold;
      font-family: "PT Sans", sans-serif;
    }

    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
    }

    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .TwitchDescription {
      color: #fff6cc;
      font-size: 36px;
      font-weight: bold;
      font-family: "PT Sans", sans-serif;
      padding: 15px 0px;
    }
    .TwitchButtonContainer {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      a {
        text-decoration: none;
      }
      .TwitchButton {
        /*  border: 2px solid #fff6cc;
        border-radius: 25px; */
        color: #fff6cc;
        font-size: 30px;
        font-weight: bold;
        font-family: "PT Sans", sans-serif;
        background: #1f1f3f;
        padding: 10px 40px;
        border-radius: 15px;
        cursor: pointer;
        &:hover {
          fill: #ffb3f3;
          color: #ffb3f3;
        }
        .TwitchLinkSVG {
          max-height: 52px;
          padding-left: 10px;
          display: block;
          margin: 0 auto;
        }
        img {
          max-height: 52px;
          display: block;
          margin: 0 auto;
          color: white;
        }
      }
    }
  }

  .AboutMe {
    margin-top: 200px;
    background: #6b6bb3;
    padding: 25px;
  }

  .AboutMeHeader {
    font-size: 72px;
    font-weight: bold;
    text-align: center;
  }

  .AboutMeBody {
    text-align: center;
    font-size: 32px;
    max-width: 60%;
    margin: 0 auto;
  }
}
.Gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: white;
  .GalleryButtonsContainer {
    min-height: 90px;
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 10px;
    z-index: 10000;
    margin: 0px;
    background-color: rgba(4, 19, 65, 0.714);
    @media (max-width: $breakpoint-small) {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: auto;
      justify-content: left;
      top: 90px;
    }
    .GalleryButtons {
      background-color: transparent;
      margin: 5px;
      color: rgba(255, 255, 255, 255);
      border: 5px solid white;
      width: 154px;
      height: 73px;
      font-size: 2em;
      cursor: pointer;
      transition: color 0.3s, border-color 0.3s;
      text-transform: none;
      font-weight: bold;
      opacity: 0.4;
      @media (max-width: $breakpoint-small) {
        flex-basis: 154px;
        flex-grow: 0;
        flex-shrink: 0;
      }
      &:hover:not(:disabled) {
        /* background-color: white; */
        //   color: #ffb3f4;
        //     border: 5px solid #ffb3f3;
        opacity: 0.8;
      }

      &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
        //    opacity: 0.4;
        //border: 5px solid #dfa70d;
        opacity: 1;
      }
    }
  }
  .GalleryImagesContainer {
    margin: 10px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    @media (max-width: $breakpoint-small) {
      margin: 30px 0px;
      width: 100%;
    }

    #TamiwithmyfavouriteYu-Gi-Oharchetypes,
    #StarfinderCrew {
      /*flex: 1 0 100%; */
    }

    #LGBalatroT span {
      text-transform: none;
    }
    @media (min-width: $breakpoint-small) {
      span {
        flex: 30%;
      }
    }

    .GalleryItem {
      max-height: 50%;
      margin-bottom: 20px;
      background-color: rgba(255, 255, 255, 0.307);
      border: 4px solid white;
      border-radius: 10px;
      position: relative;
      padding: 10px 20px 20px 20px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

      .GalleryAudioContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        .GalleryAudioIcon {
          width: 30px;
          filter: invert(100%) sepia(4%) saturate(7461%) hue-rotate(186deg)
            brightness(110%) contrast(102%);
          margin-right: 10px;
          cursor: pointer;
          &:hover {
            filter: invert(85%) sepia(90%) saturate(2421%) hue-rotate(278deg)
              brightness(105%) contrast(109%);
          }
        }
        p {
          display: inline;
          margin-bottom: 13px;
        }
      }

      .GalleryText {
        // padding-bottom: 5px;
        //   padding-left: 5px;
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.5em;
        text-align: center;
        padding-bottom: 10px;
      }
      .PixelArtImage {
        image-rendering: -webkit-optimize-contrast;
      }
      .GalleryImage {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        height: auto;
        display: block;
        width: 100%;
        &:before {
          content: "";
          position: absolute;
          top: 5px; /* Adjust the value to control the distance of the border from the inner content */
          right: 5px;
          bottom: 5px;
          left: 5px;
          border: 2px solid transparent; /* Transparent border to start */
          transition: border-color 0.3s ease; /* Smooth transition */
        }
        &:hover {
          filter: brightness(70%) sepia(100%) hue-rotate(260deg);
        }
      }
      position: relative;
      .GalleryVideo {
        display: block;
        iframe {
          width: 100%;
        }
      }
      .GalleryLink {
        img {
          width: 30px;
          margin-right: 10px;
        }
        font-size: 2em;
        display: block;
        text-align: center;
        font-weight: bold;
        margin-top: 25px;
      }
      .GalleryImageOverlay {
        position: relative;
        display: inline-block;
        background: cyan;
        display: inline-block;
        & > img {
          vertical-align: middle;
        }
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0;
          transition: 0.5s ease;
          &hover {
            opacity: 0.23;
          }
        }
      }

      &:hover {
        /* background-color: white;
        color: black;
        FFB3F3
        */

        /*
        &:after{
          position: absolute; 
          content: '';
          display: block;
          top: 0;
          left: 0; 
          right: 0; 
          bottom: 0; 
          background: rgba(255, 255, 255, 0.15); 
       
        }*/
      }
    }
  }
  .GalleryLoadMoreButton {
    width: 100%;
    height: 100px;
    margin: 40px;

    background-color: transparent;
    color: white;
    border: 2px solid white;
    padding: 10px 20px;
    font-size: 30px;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
      color: #ffb3f3;
      border: 2px solid #ffb3f3;
    }
  }
}

.CommissionInfo {
  width: 66vw;
  margin: 50px auto;
  margin-top: 20px;
  padding: 20px;
  padding-top: 50px;
  border-radius: 25px;
  background: #6b6bb3;
  @media (max-width: $breakpoint-small) {
    margin: 5px auto;
    width: 100%;
  }
  .CommissionInfoStatus {
    display: flex;
    font-size: 2em;
    width: 100%;
    align-items: center;
    text-transform: uppercase;
    @media (max-width: $breakpoint-small) {
      display: block;
      text-align: center;
      margin-top: 20px;
    }
    .CommissionInfoStatusShowmanship {
      background: black;
      margin-left: 35px;
      justify-self: center;
      flex: 1;
      border-width: 2px;
      text-align: center;
      padding: 5px 0px;
      letter-spacing: 0.2em;
      font-size: 1em;

      border: none;
      outline: none;
      background: rgb(255, 255, 255);
      cursor: pointer;
      position: relative;
      z-index: 0;
      border-radius: 10px;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      @media (max-width: $breakpoint-small) {
        margin-top: 10px;
      }

      &:hover {
        color: #e5e7ef;
        &::after {
          background-color: #1f1f3f;
        }
      }
    }

    .blinking {
      animation: blinkingText 1.25s infinite;
      &:before {
        content: "";
        background: linear-gradient(
          45deg,
          #ff0000,
          #ff7300,
          #fffb00,
          #48ff00,
          #00ffd5,
          #002bff,
          #7a00ff,
          #ff00c8,
          #ff0000
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing-button-85 20s linear infinite;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
      }

      &:after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
        left: 0;
        top: 0;
        border-radius: 10px;
      }
    }
    @keyframes blinkingText {
      0% {
        color: rgb(255, 255, 255);
      }
      50% {
        color: rgb(242, 20, 138);
      }
      100% {
        color: rgb(255, 255, 255);
      }
    }
  }

  .CommissionInfoApplicationBody {
    font-size: 1.4em;
  }
  .CommissionThankYou {
    text-align: center;
  }
  .CommissionInfoHeader {
    text-align: center;
    letter-spacing: 0.1rem;
    width: calc(100% + 0.1rem);
    font-size: 1.875em;
    line-height: 1.375;
    font-weight: 200;
    padding-top: 50px;
    text-transform: uppercase;
  }

  .CommissionInfoTLDR {
    background-color: #6b6bb3;
    padding: 5px;
    border-radius: 5px;
    ul {
      li {
        font-size: 1.5em;
      }
    }
  }

  .CommissionInfoSubHeader {
    padding: 50px 0px;
    text-align: center;
    max-width: 40vw;
    margin: 0 auto;
    font-size: 1.5em;
    .links {
      display: flex;
      justify-content: center;
      .inlineLink {
        color: white;
        font-weight: bold;
        font-size: 1.6em;
        padding-left: 10px;
        margin: 0 auto;
        display: block;
      }
    }
  }

  .CommissionInfoApplicationSubHeader {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6em;
  }

  .CommissionInfoApplicationSubHeaderButtons {
    display: flex;
    justify-content: center;

    .CommissionInfoAppButton {
      background-color: transparent;
      margin: 5px;
      color: white;
      border: 2px solid white;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      transition: color 0.3s, border-color 0.3s;

      &:hover {
        /* background-color: white; */
        color: #ffb3f3;
        border: 2px solid #ffb3f3;
      }
    }
    .active {
      cursor: not-allowed;
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .CommissionInfoApplicationBodyItalics {
    font-style: italic;
    padding: 10px 0px;
    font-size: 1.2em;
  }

  ul {
    li {
      padding: 5px 0px;
      font-size: 1.4em;
    }
  }

  .DosContainer {
    li {
      list-style: none;
    }
    li::before {
      content: "";
      display: inline-block;
      height: 15px;
      width: 15px;
      margin-right: 3px;
      background-image: url("./assets/check-solid.svg");
    }
  }

  .DontsContainer {
    /*
    list-style: georgian inside url('./assets/x-solid.svg');
     */
    li {
      list-style: none;
    }
    li::before {
      content: "";
      display: inline-block;
      height: 15px;
      width: 15px;
      background-repeat: no-repeat;
      margin-right: 3px;
      background-image: url("./assets/x-solid.svg");
    }
  }

  .CommissionInfoApplicationListSubHeader {
    margin-left: -40px;
    font-style: italic;
    font-size: 1.2em;
  }

  .CommissionInfoApplicationDisclaimer {
    padding-bottom: 20px;
    font-weight: bold;
    text-align: center;
    width: 50vw;
    font-size: 1.2em;
    margin: 0 auto;
  }
  .CommissionInfoPricingContainer {
    display: flex;
    @media (max-width: $breakpoint-small) {
      display: block;
    }
  }
  .CommissionInfoPricingLeftImageContainer {
    padding: 2rem;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: inline-flex;
    width: auto;
    flex-wrap: wrap;
    flex-grow: 1;
    @media (max-width: $breakpoint-small) {
      display: block;
      width: 100%;
    }
    .CommissionInfoPricingImage {
      max-width: 30vw;
      border: 4px solid white;
      border-radius: 3px;
      @media (max-width: $breakpoint-small) {
        width: 80%;
        display: block;
        max-width: 80%;
      }
    }
  }
  .CommissionInfoPricingRightContainer {
    padding: 1.3rem;
    p {
      font-size: 1em;
    }
    .CommissionInfoPricingHeader {
      font-size: 1.8em;
      font-style: italic;
      margin: 0px;
    }

    .CommissionInfoPricingBody {
      margin-top: 20px;
    }
  }
  .CommissionInfoStatusShowmanship {
    background: black;
    margin-left: 35px;
    justify-self: center;
    flex: 1;
    border-width: 2px;
    text-align: center;
    padding: 5px 0px;
    letter-spacing: 0.2em;

    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    @media (max-width: $breakpoint-small) {
      margin-top: 10px;
    }
    font-size: 2em;

    &:hover {
      color: #e5e7ef;
      &::after {
        background-color: #1f1f3f;
      }
    }
  }
}
.DoodleBody {
  background: #6b6bb3;
  padding: 25px;
  border-radius: 15px;
  margin: 50px auto;
  max-width: 800px;
  color: $text-color;
  font-family: "PT Sans", sans-serif;
  display: flex;
  br{
    margin: 10px;  
    display: block;
    content: " ";
  }
  .DoodleLeftContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; 

    .DoodleHeader {
      font-size: 2.5em;
      font-weight: bold;
      margin: 0px; 
    }

    .DoodleDate {
      font-size: 1.2em;
      margin: 0px; 
      color: lighten($text-color, 20%);
    }

    .DoodleText {
      flex: 1;
      margin-right: 20px;
    }
  }
  .DoodleRightContainer{
    width: 100%;
    .DoodleImage{
      width: 100%; 
      border-radius: 20px; 
    }
  }
}
